import React, { Component } from 'react';
import './css/style.css';
import movieLister from './image/movielister_top.svg';
import brokenImage from './image/noImage.svg';
import { v4 as uuidv4 } from 'uuid';
import { withCookies, Cookies } from 'react-cookie';
import './Service';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

const socketURL = process.env.NODE_ENV === 'development' ? `ws://localhost:8080` : `wss://movility.host.r-l.dev`;

console.log(socketURL, process.env.NODE_ENV);

class App extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    const uuid = cookies.get('uuid');
    if (!uuid) {
      cookies.set('uuid', uuidv4());
    }
  }

  state = { connected: false, movies: null, error: false, fullDesc: {}, registered: false };

  initializePushNotifications = async () => {
    const uuid = await Device.getId();
    if (Capacitor.getPlatform() === 'ios') {
      try {
        // Request permission to use push notifications
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          console.log('User denied push notification permissions');
          return;
        }

        // Register for push notifications
        await PushNotifications.register();

        // Add listeners for push notifications
        await PushNotifications.addListener('registration', (token) => {
          console.log('Push registration initialized:', token.value);
          try {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
              const socketData = {
                target: 'DEVICE_PUSH_REGISTRATION',
                data: {
                  pushToken: token.value,
                  // uuid: this.props.cookies.get('uuid'),
                  uuid: uuid.identifier,
                  platform: 'ios',
                },
              };
              this.socket.send(JSON.stringify(socketData));
              this.props.cookies.set('pushUUID', this.props.cookies.get('uuid'));
              this.setState({ registered: true });
              console.log('Push registration success:', token.value);
            }
          } catch (error) {
            console.error('Error on registration:', error);
          }
        });

        await PushNotifications.addListener('registrationError', (error) => {
          console.error('Error on registration:', error);
        });

        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
          console.log('Push notification received:', notification);
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
          console.log('Push notification action performed:', notification);
        });
      } catch (error) {
        console.error('Error setting up push notifications:', error);
      }
    } else if ('serviceWorker' in navigator) {
      // Existing web push notification logic
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('Device successfully registered');
          registration.pushManager.getSubscription().then((subscription) => {
            if (subscription && this.socket.readyState) {
              const pushToken = subscription.endpoint;
              const key = subscription.getKey('p256dh');
              const token = subscription.getKey('auth');
              console.log('Register Push Subscription');
              const socketData = {
                target: 'DEVICE_PUSH_REGISTRATION',
                data: {
                  pushToken,
                  p256dh: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
                  auth: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
                  uuid: this.props.cookies.get('uuid'),
                  platform: 'web',
                },
              };
              this.socket.send(JSON.stringify(socketData));
              this.props.cookies.set('pushUUID', this.props.cookies.get('uuid'));
              this.setState({ registered: true });
            }
          });
        })
        .catch((error) => console.log(error));
    }
  };

  componentDidMount() {
    this.connectWebSocket();
    // this.initializePushNotifications();
  }

  componentDidUpdate() {
    if (!this.state.registered && this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.initializePushNotifications();
    }
  }

  connectWebSocket = () => {
    this.socket = new WebSocket(socketURL);

    this.socket.onopen = () => {
      console.log('WebSocket connected');
      this.setState({ connected: true, error: false });
    };

    this.socket.onmessage = ({ data }) => {
      this.setState({ movies: JSON.parse(data) });
    };

    this.socket.onerror = (error) => {
      console.log('WebSocket error occurred:', error);
      this.setState({ error: true });
      this.reconnectWebSocket();
    };

    this.socket.onclose = () => {
      console.log('WebSocket disconnected');
      this.setState({ connected: false });
      this.reconnectWebSocket();
    };
  };

  reconnectWebSocket = () => {
    console.log('Attempting to reconnect...');
    setTimeout(() => {
      if (!this.state.connected) {
        this.connectWebSocket();
      }
    }, 1000 * 60); // Try to reconnect after 5 seconds
  };

  noImage = (event) => {
    event.target.src = brokenImage;
    event.target.style = 'opacity: 0.1';
  };

  getRatingStars = (value) => {
    let value_ = value.replace(/[a-z,A-Z]/g, '').split('/')[0];
    let starCount = Math.ceil(value_);
    let rating = [];
    for (let i = 1; i <= 10; i++) {
      if (i <= starCount) {
        if (i == starCount) {
          let limit_ = Math.ceil(100 * (value_ - (i - 1)));
          rating.push(
            <div
              key={i}
              className="ratingStar"
              style={{
                background: `var(--p_star_grey)`,
                backgroundImage: `linear-gradient(to right, var(--p_star) ${limit_}%, rgba(0,0,0,0) ${limit_}%)`,
              }}></div>
          );
        } else {
          rating.push(<div key={i} className="ratingStar" style={{ background: `var(--p_star)` }}></div>);
        }
      } else {
        rating.push(<div key={i} className="ratingStar" style={{ background: `var(--p_star_grey)` }}></div>);
      }
    }
    return rating;
  };

  drawNewcard = (movie, key) => {
    let description_ = movie.description;
    if (movie.description.length > 250 && !Object.keys(this.state.fullDesc).filter((el) => `${el}` == `${key}!`).length > 0) {
      description_ = movie.description.substring(0, 250) + '...';
    }
    return (
      <div className="movie" key={key}>
        <div className="left">
          <div className="poster">
            <img src={'https://movility.robertlangbein.com/' + movie.cover} alt={movie.title + ' Poster'} onError={this.noImage} />
          </div>
          <div className="info_left">
            <span className="material-symbols-rounded" style={{ color: `var(--s_${movie.quality.sound})` }}>
              volume_up
            </span>
          </div>
        </div>
        <div className="center" style={{ background: `var(--g_${movie.quality.picture})` }}></div>
        <div className="right">
          <div className="title">
            <h1>{movie.title}</h1>
            <div className="imdb">{this.getRatingStars(movie.rating)}</div>
          </div>
          <div className="release">
            <p>{movie.source}</p>
          </div>
          <div className="description">
            <h2>Beschreibung:</h2>
            <p
              onClick={() => {
                if (Object.keys(this.state.fullDesc).filter((el) => `${el}` == `${key}!`).length > 0) {
                  let temp_ = this.state.fullDesc;
                  delete temp_[`${key}!`];
                  this.setState({ fullDesc: temp_ });
                } else {
                  this.setState({
                    fullDesc: { ...this.state.fullDesc, [`${key}!`]: true },
                  });
                }
              }}>
              {description_}
            </p>
          </div>
          <div className="additional">
            <span>
              <b>Genre:</b> {movie.category.replace(',', ', ')}
            </span>
            <span>
              <b>Jahr:</b> {movie.date}
            </span>
            <span>
              <b>Dauer:</b> {movie.length}
            </span>
            <span>
              <b>IMDb:</b> {movie.rating}
            </span>
          </div>
          <div className="info_right">
            <span className="material-symbols-rounded" style={{ color: `var(--s_${movie.quality.sound})` }}>
              volume_up
            </span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="movieContainer">
        <div id="fadeground"></div>
        <div id="top_graphic">
          <img src={movieLister} alt="Logo Grafik" />
        </div>
        <div className="CardContainer no-scrollbar" id="list">
          {this.state.movies
            ? this.state.movies.map((movie, key) => {
                return this.drawNewcard(movie, key);
              })
            : ''}
        </div>
      </div>
    );
  }
}

export default withCookies(App);
